.App {
    height: 100%;
}

/* .selected-element-cursor {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: 5px solid yellow;
} */

.canvas-panel {
    width: calc(100% - 300px);
    height: 100%;
    position: relative;
    overflow: hidden;
    transform-style: preserve-3d;
    perspective: 800px;
}

.add-new-element-btn {
    margin-bottom: 20px;
}
.apply-to-all-switch {
    margin-top: 10px;
}

.control-panel {
    background-color: #edeff2;
    padding: 20px 10px 20px 20px;
    border-radius: 6px;
    width: 300px;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    overflow: auto;
}

.control-panel-title {
    cursor: pointer;
}

.control-panel-group {
    margin-bottom: 15px;
}

.control-panel-actions {
    margin-bottom: 20px;
}

.control-panel-group-title-collapse-icon {
    margin-right: 5px;
    cursor: pointer;
}

.position-control {
    margin: 10px 0;
}

.color-indicator {
    cursor: pointer;
    width: 80px;
    height: 30px;
    margin-right: 5px;
    border: 1px solid #9e9e9e;
    background-color: white;
}

.color-indicator-content {
    width: 90%;
    height: 70%;
    margin: 0 auto;
    margin-top: 4px;
}

.position-control-select {
    width: 130px;
}

.position-map {
    border: 2px dashed gray;
    width: 100%;
    height: 90px;
    position: relative;
}

.position-map-row {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
}
.position-map-top-row {
    top: 0;
    left: 0;
}

.position-map-center-row {
    top: 28px;
}

.position-map-bottom-row {
    bottom: 0;
    left: 0;
}

.control-panel-group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.control-panel-action-group:after {
    content: '';
    display: block;
    clear: both;
}

.control-panel-group-title-enabled {
    margin-bottom: 0;
}

.control-panel-horizontal-layout {
    display: flex;
    justify-content: space-between;
}

.control-panel-horizontal-layout-item {
    margin: 0 10px;
}

.control-panel-horizontal-layout-item:first-child {
    margin-left: 0;
}

.control-panel-horizontal-layout-item:last-child {
    margin-right: 0;
}

.border-group-toggles {
    margin-top: 10px;
}

.control-panel-border-layout {
    display: flex;
    justify-content: center;
    align-items: center;
}

.control-panel-border-layout-label-item {
    width: 55px;
}

.control-panel-border-layout-width-item {
    width: 70px;
    margin-right: 10px;
}

.control-panel-border-layout-style-item {
    width: 25%;
}

.control-panel-border-layout-color-item {
    width: 40px;
    margin-left: 10px;
}

.border-color-indicator {
    width: 40px;
    height: 30px;
    margin-right: 5px;
    margin-top: 5px;
    border: 1px solid #9e9e9e;
    background-color: white;
}

.border-color-indicator-content {
    width: 83%;
    height: 70%;
    margin: 0 auto;
    margin-top: 4px;
}

.add-multiple-dialog {
    background: #edeff2;
}

.add-multiple-dialog-content {
    margin: 20px;
}

.add-multiple-dialog-actions {
    margin: 0 20px;
    display: flex;
    justify-content: flex-end;
}

.add-multiple-dialog-actions-btn {
    margin-left: 10px;
}

.transform-slider-container {
    width: 180px;
    margin-left: 10px;
    margin-top: 7px;
}

.transform-skew-container {
    display: flex;
    justify-content: space-around;
}

.control-panel-transform-group-subtitle {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.animation-panel {
    position: absolute;
    padding: 20px 10px 20px 20px;
    height:96%;
    width: 260px;
    background-color: #edeff2;
    top: 2%;
    right: 20px;
    z-index: 9999;
    overflow: auto;
}

.animation-panel-content {
    height: 100%;
}
.animation-slider {
    width: 187px;
    margin-left: 8px;
    margin-top: 8px;
}

.animation-group {
    margin-top: 10px;
}

.animation-property-selector {
    padding: 10px 20px;
}

.animation-status-label {
    display: block;
    margin: 2px 0;
}

.animation-status-label:first-child {
    margin-top: 5px;
}

.animation-timeline-group:last-child {
    padding-bottom: 15px;
}

.footer {
    text-align: center;
    position: absolute;
    bottom: 15px;
    left: 50%;
    color: lightgray;
    z-index: 1;
}

.copy-code-style-dialog-footer {
    margin-top: 10px;
    margin-bottom: -10px;
}

.gradient-preview {
    height: 160px;
    margin-bottom: 20px;
    background:white;
}

.gradient-dialog {
    width: 520px; 
    margin-bottom: 20px; 
    z-index: 1
}


.gradient-panel__angle {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.gradient-panel__angle__label {
    margin-right: 10px;
}

.gradient-panel__angle__picker {
    margin-left: 10px;
}

.gradient-panel__angle__input {
    width: 45px;
    display: inline-block;
    margin-left: 10px;
}

.gradient-panel__meta {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.gradient-panel__presets {
    display: flex; 
    align-items: center;
    justify-content: flex-start;
}

.gradient-panel__presets__label {
    font-size: 12px;
}

.gradient-panel__presets__item {
    height: 40px;
    width: 40px;
    border: 1px solid rgba(17, 20, 24, 0.15);
    margin: 0 5px;
    cursor: pointer;
}

.gradient-panel__presets__item:first-of-type{
    margin-left: 15px;
}

.gradient-panel__slider {
    width: 100%;
    height: 20px;
    border-radius: 10px;
    margin: 0 auto;
    position: relative;
    cursor: crosshair;
    box-shadow: inset 1px 1px 4px grey;
    margin-bottom: 20px;
}
.gradient-panel__slider-marker {
    position: absolute;
    height: 30px;
    width: 30px;
    left: 30px;
    top: -5px;
    border-radius: 15px;
    cursor: move;
    background: white;
    box-shadow: 0px 2px 6px grey;
}

.gradient-panel__slider-marker__color {
    position: absolute;
    height: 22px;
    width: 22px;
    left: 4px;
    top: 4px;
    border-radius: 11px;
    cursor: move;
    background: #c9c9c9;
}

.gradient-panel__color-item {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gradient-panel__color-item__info {
    display: flex;
    align-items: center;
}

.gradient-panel__color-item__action {
    display: flex;
}

.gradient-panel__color-item__position {
    width: 65px;
    display: inline-block;
}

.gradient-panel__color-item__position__unit {
    margin-top: 8px;
    margin-left: 4px;
    color: gray;
}

.gradient-panel__color-item__picker {
    width: 60px;
    height: 30px;
    display: inline-block;
    cursor: pointer;
    border: 3px solid white;
    border-radius: 4px;
}

.gradient-panel__color-item__color-text {
    width: 90px;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}

.gradient-panel__color-item__btn {
    display: inline-block;
    margin-left: 14px;
    cursor: pointer;
}

.gradient-panel__color-item__btn-disabled {
    color: lightgrey;
    cursor: not-allowed;
}

.gradient-panel__divider {
    width: 0px;
    height: 30px;
    border-left: 1px solid rgba(17, 20, 24, 0.15);
    margin: 0 10px;
}

.overlay-when-drag {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: move;
    /* background: rgba(255, 0, 0, 0.3); */
}

.background-gradient-switch {
    margin: 10px 0 0 0;
}

.tips {
    margin-top: 10px;
}



